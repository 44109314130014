<template>
  <div class="">
    <b-row class="m-0">
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="12"
          md="10"
          :lg="isAuthenticated ? 8 : 6"
          class="mx-auto"
        >
          <b-card :title="$t('Redeem Codes')">
            <b-row>
              <b-col>
                <b-card-text class="mb-1">
                  {{ `${this.$t('Step 1')}: ${this.$t('Download the app')}` }}
                </b-card-text>
                <b-card-text class="mb-1">
                  {{ `${this.$t('Step 2')}: ${this.$t('Create your account')}` }}
                </b-card-text>
                <b-card-text class="mb-1">
                  {{ `${this.$t('Step 3')}: ${this.$t('Activate redeem code')}` }}
                </b-card-text>
                <b-card-text class="mb-2">
                  {{ `${this.$t('Step 4')}:` }}
                  <small class="h5">{{ this.$t('You\'re all Set !') }}</small>
                </b-card-text>
              </b-col>
              <b-col class="logo-container">
                <b-img
                  :src="require('@/assets/images/pages/get-it-on-google-play.svg')"
                  height="35"
                  alt="Get it on google play"
                  @click="onLogoClicked('android')"
                />
                <b-img
                  :src="require('@/assets/images/pages/available-on-the-app-store.svg')"
                  height="35"
                  alt="Avaiable on the app store"
                  @click="onLogoClicked('ios')"
                />
              </b-col>
            </b-row>
            <!-- form -->
            <validation-observer
              ref="redeemCodesForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="redeemCodesNow"
              >
                <!-- Spider email account -->
                <b-form-group
                  v-if="!isAuthenticated"
                  :label="$t('Player email')"
                  label-for="player-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Player email')"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="player-email"
                      v-model="playerEmail"
                      :state="errors.length > 0 ? false : null"
                      name="player-email"
                      placeholder="user@mail.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Redeem Codes -->
                <b-form-group
                  :label="$t('Redeem Codes')"
                  label-for="redeem-codes"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Redeem Codes')"
                    rules="required"
                  >
                    <b-form-input
                      id="redeem-codes"
                      v-model="code"
                      :state="errors.length > 0 ? false : null"
                      name="redeem-codes"
                      placeholder="CODE1234"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || isLoading"
                >
                  {{ btnTitle }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
    <b-modal
      id="modal-confirmation"
      v-model="modalShow"
      content-class="shadow"
      :title="$t('Confirm')"
      no-fade
      centered
      :ok-title="$t('YES')"
      :cancel-title="$t('NO')"
      @ok.prevent="apply"
    >
      <span>{{ modalTitle }}</span>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCardText,
  BForm,
  BButton,
  BCard,
  BImg,
} from 'bootstrap-vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      userData: null,
      playerEmail: this.userData ? this.userData.mail : null,
      code: null,
      stripe: null,
      modalTitle: null,
      modalSubTitle: null,
      modalShow: false,
      isLoading: false,
      isAuthenticated: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    btnTitle() {
      return this.isLoading ? this.$t('Loading...') : this.$t('Redeem now !').toUpperCase()
    },
  },
  async created() {
    this.stripe = window.Stripe('pk_live_uj39bIxDKequNB3q849L0ft4')
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.playerEmail = this.userData ? this.userData.mail : null
    this.isAuthenticated = this.userData !== null && this.userData !== undefined
    if (this.$route.query.email && this.$route.query.code) {
      await this.verify()
    }
  },
  methods: {
    resetForm() {
      this.code = ''
      this.playerEmail = this.userData ? this.userData.mail : ''
    },
    async redeemCodesNow() {
      const statusMap = {
        UP: {
          title: (from, to) => this.$t('Do you want to upgrade from {0} -> {1}? If so, make sure to cancel your active subscription from your Store (iOS) or (Android) to avoid multiple payments.', { 0: from, 1: to }),
        },
        EXTEND: {
          title: () => 'Please make sure to cancel your active subscription from your Store (iOS) or (Android) to avoid multiple payments.',
        },
        DOWN: {
          title: (from, to) => this.$t('Do you want to downgrade from {0} -> {1}? If so, make sure to cancel your active subscription from your Store (iOS) or (Android) to avoid multiple payments.', { 0: from, 1: to }),
        },
      }

      const typeMap = {
        FREEMIUM: 'FREEMIUM',
        PERFORMANCE: 'PERFORMANCE',
        TOUR: 'TOUR',
      }

      this.isLoading = true
      const isValid = await this.$refs.redeemCodesForm.validate()
      if (!isValid) return

      try {
        const body = {
          code: this.code.trim(),
          email: this.playerEmail.trim(),
        }
        const response = await axios.post('/api/subscription/promotion-codes/validate/', body)
        if (!response || !response.data) return
        // handle FREEMIUM case
        const { status } = response.data
        if (response.data.code_type === typeMap.FREEMIUM) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.isLoading = false
          return
        }
        // handle subscription type is FREEMIUM
        if (response.data.subscription_type === typeMap.FREEMIUM) {
          this.apply()
          this.resetForm()
          await this.$refs.redeemCodesForm.reset()
          return
        }
        // handle TOUR, PERFORMANCE cases
        this.modalShow = true
        this.modalTitle = this.$t(statusMap[status].title(this.$t(response.data.subscription_type), this.$t(response.data.code_type)))
        this.isLoading = false
      } catch (e) {
        if (!e || !e.response || !e.response.data) return
        let errorMsg = this.$t("We couldn't process your request. Please contact us at support@spidergolf.fr")
        if (e.response.status === 400 && e.response.data) {
          if (e.response.data.code) {
            errorMsg = this.$t(e.response.data.code[0])
          } else if (e.response.data.email) {
            errorMsg = this.$t(e.response.data.email[0])
          }
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Request failed'),
            icon: 'SlashIcon',
            variant: 'danger',
            text: errorMsg,
          },
        })
        this.isLoading = false
      }
    },
    async apply() {
      const link = `${window.location.href}?email=${this.playerEmail.trim()}&code=${this.code.trim()}`
      const body = {
        code: this.code.trim(),
        email: this.playerEmail.trim(),
        success_url: link,
        cancel_url: link,
      }
      try {
        const response = await axios.post('/api/subscription/promotion-codes/apply/', body)
        if (!response) return
        this.isLoading = false
        await this.stripe.redirectToCheckout({ sessionId: response.data.id })
      } catch (e) {
        let errorMsg = this.$t("We couldn't process your request. Please contact us at support@spidergolf.fr")
        if (e.response.status === 400 && e.response.data) {
          if (e.response.data.code) {
            errorMsg = this.$t(e.response.data.code[0])
          } else if (e.response.data.email) {
            errorMsg = this.$t(e.response.data.email[0])
          }
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Request failed'),
            icon: 'SlashIcon',
            variant: 'danger',
            text: errorMsg,
          },
        })
        this.isLoading = false
      }
    },
    async verify() {
      const body = {
        code: this.$route.query.code,
        email: this.$route.query.email,
      }
      try {
        const response = await axios.post('/api/subscription/promotion-codes/verify-session/', body)
        if (!response) return
        this.isLoading = false
        this.$router.replace(this.isAuthenticated ? '/input-redeem-codes' : '/redeem-codes')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: this.$t('The code is applied successfully'),
          },
        })
        this.resetForm()
      } catch (e) {
        let errorMsg = this.$t("We couldn't process your request. Please contact us at support@spidergolf.fr")
        if (e.response.status === 400 && e.response.data) {
          if (e.response.data.code) {
            errorMsg = this.$t(e.response.data.code[0])
          } else if (e.response.data.email) {
            errorMsg = this.$t(e.response.data.email[0])
          } else {
            errorMsg = this.$t(e.response.data.message)
          }
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Request failed'),
            icon: 'SlashIcon',
            variant: 'danger',
            text: errorMsg,
          },
        })
        this.isLoading = false
      }
    },
    onLogoClicked(os) {
      const linkMap = {
        android: 'https://play.google.com/store/apps/details?id=fr.spidergolf.spidergolf',
        ios: 'https://apps.apple.com/us/app/spider-golf/id1270051002',
      }
      window.open(linkMap[os], '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

small {
  color: $blue;
}

.logo-container {
  text-align: end;
  img {
    border: 1px solid $primary;
    border-radius: 6px;
    &:last-child {
      margin-left: 1rem;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
